import { useState } from 'react'
import { Layout, Menu, Avatar, Input, Badge, Button } from 'antd'
import {
  SideBarContainer,
  SidebarItemsContainer,
  SidebarLogo,
  SidebarItem,
  SearchIcon,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { handleCurrentPage } from '../../../redux/app/appSlice'
import { useSelector, useDispatch } from 'react-redux'
import largeLogo from '../../../assets/starthubLogo.png'
import avatar from '../../../assets/avatar.png'
import GetLogo from '../../getlogo/getlogo'
import NameAvatar from '../../../common/NameAvatar'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import './styles'

const { Sider } = Layout

const LargeSidebar = ({
  secondMenuitems,
  menuItems,
  handleCollapsed,
  collapsed,
  logOutuser,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const dispatch = useDispatch()
  const { app, auth } = useSelector((state) => state)
  const navigate = useNavigate()
  const handleCLikItem = (item) => {
    if (item.path !== '') {
      item?.label !== 'Menu' && dispatch(handleCurrentPage(item?.label))
      item?.path && navigate(item?.path)
    }
  }

  const filteredMenuItems = menuItems.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  )
  const SecondfilteredMenuItems = secondMenuitems.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  )
  return (
    <SideBarContainer collapsed={collapsed}>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          width: '100%',
          overflowX: 'hidden',
        }}
        trigger={null}
        collapsible
        className="side-bar"
      >
        <SidebarItemsContainer>
          <SidebarLogo>
            <div className="startHub-log" onClick={handleCollapsed}>
              <img src={largeLogo} alt="logo" className="logo" />
            </div>
          </SidebarLogo>
          <SidebarItem>
            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              className="sidebar-menu"
            >
              {filteredMenuItems?.map((item) => (
                <Menu.Item
                  icon={item?.icon}
                  // label={item?.label}
                  key={item?.key}
                  // title={item?.label}
                  onClick={() => handleCLikItem(item)}
                  className={
                    app?.currentPage == item?.label
                      ? `custom-active-item ${item?.className}`
                      : item?.className
                  }
                >
                  <div className="title">
                    {item?.label}

                    {item?.isBadge && (
                      <Badge
                        count={item?.count}
                        size="default"
                        className="badges"
                      ></Badge>
                    )}
                  </div>
                </Menu.Item>
              ))}
            </Menu>

            <Menu
              mode="inline"
              defaultSelectedKeys={['1']}
              className="sidebar-menu-two"
            >
              <div className="app-title">Starthub apps</div>
              {SecondfilteredMenuItems?.map((item) => (
                <Menu.Item
                  icon={item?.icon}
                  // label={item?.label}
                  key={item?.key}
                  // title={item?.label}
                  onClick={() => handleCLikItem(item)}
                  className={
                    app?.currentPage == item?.label
                      ? `custom-active-item ${item?.className}`
                      : item?.className
                  }
                >
                  <div className="title">
                    {item?.label}

                    {item?.isBadge && (
                      <Badge count={item?.count} className="badges"></Badge>
                    )}
                  </div>
                </Menu.Item>
              ))}
            </Menu>

            <Menu className="sidebar-menu-two logout-div" mode="inline">
              <Menu.Item
                className="menu-item"
                icon={GetLogo('logout')}
                onClick={logOutuser}
              >
                <div className="title">Logout</div>
              </Menu.Item>
              <div className="compnay-watermark">Powered by StartHub</div>
            </Menu>
          </SidebarItem>
        </SidebarItemsContainer>
      </Sider>
    </SideBarContainer>
  )
}

export default LargeSidebar
