import React, { Fragment, Suspense, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  Navigate,
} from 'react-router-dom'
import { Layout, theme } from 'antd'
import { useSelector } from 'react-redux'
import SideBar from './sidebar/SideBar'
import AppRoutes from '../routes/AppRoutes'
import 'react-toastify/dist/ReactToastify.css'
import PageLoader from '../common/PageLoader'
import Login from '../pages/auth/Login'
const { Content } = Layout
const Layouts = () => {
  const { auth, app } = useSelector((state) => state)
  const {} = theme.useToken()
  return (
    <Router>
      <Layout style={{ minHeight: '100vh', backgroundColor: 'white' }}>
        {auth?.isLogin && app?.currentPage != 'login' ? (
          <SideBar collapsed={app?.sidebarCollapsed}></SideBar>
        ) : (
          ''
        )}
        <Layout
          style={{
            marginLeft: !app?.sidebarCollapsed && auth?.isLogin ? '272px' : '',
          }}
        >
          <Content style={{ backgroundColor: '#fff' }}>
            <Suspense>
              <Routes>
                {AppRoutes?.map((route) => {
                  if (route?.isProtected && auth?.isLogin) {
                    return (
                      <Route
                        key={route?.id}
                        path={route?.pathName}
                        Component={route?.component}
                      />
                    )
                  } else if (!route?.isProtected) {
                    return (
                      <Route
                        key={route?.id}
                        path={route?.pathName}
                        Component={route?.component}
                      />
                    )
                  }
                })}
                {!auth?.isLogin && (
                  <Route path="*" element={<Navigate to="/login" />} />
                )}
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Router>
  )
}
export default Layouts
