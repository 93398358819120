import React from 'react'
import styled from 'styled-components'

const NameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.val ? `${props.val}px` : '107px')};
  height: ${(props) => (props.val ? `${props.val}px` : '107px')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '24px')};
  border-radius: 50%;
  background-color: #4cbbcb;
  color: #fff;
  font-weight: 600;
`
export default function NameAvatar({ userName, val, fontSize }) {
  const GetInitialLetter = () => {
    const initials = userName?.slice(0, 2) // Get the first two letters of the userName
    return initials?.toUpperCase()
  }
  return (
    <NameDiv val={val} fontSize={fontSize}>
      {GetInitialLetter()}
    </NameDiv>
  )
}
