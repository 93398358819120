// src/features/counterSlice.js
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  currentPage: '',
  sidebarCollapsed: true,
  mailBoxLocationId: '',
}

export const appSlice = createSlice({
  name: 'AppAuth',
  initialState,
  reducers: {
    handleCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    handleCollapsed: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed
    },
    handMailBoxLocationId: (state, action) => {
      state.mailBoxLocationId = action.payload
    },
  },
})

export const { handleCurrentPage, handleCollapsed, handMailBoxLocationId } =
  appSlice.actions

export default appSlice.reducer
