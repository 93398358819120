import styled, { keyframes } from 'styled-components'

const SideBarContainer = styled.nav`
  height: 100%;
  background: var(--White-100, #fff);
  box-shadow: none;
  .ant-layout-sider-children {
    width: 100%;
  }
  .ant-menu {
    border-inline-end: none !important;
  }
  .ant-menu::before {
    display: none;
  }
  .ant-menu::after {
    display: none;
  }
  .side-bar {
    width: 272px !important;
    min-width: 272px !important;
    max-width: 272px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: none !important;
    background: var(--White-100, #fff);
    box-shadow: 0px 40px 250px 0px rgba(9, 79, 99, 0.35);
  }

  .side-bar::-webkit-scrollbar {
    width: 3px;
  }

  .side-bar::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  .side-bar::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }

  .sidebar-menu {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 208px;
    margin-left: 32px;
    gap: 14px;
    align-self: stretch;
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    border-right: none;
    border-inline-end: none;

    .menu-title {
      display: flex;
      padding: 8px 0px;
      align-items: center;
      align-self: stretch;
      .title {
        color: var(--Dark-Gray-100, #2b3746);

        /* P2 */
        font-family: Outfit;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 133.333% */
        text-transform: uppercase;
      }
    }

    .menu-title:focus,
    .menu-title:hover {
      .title {
        cursor: default !important;
        color: var(--Dark-Gray-100, #2b3746) !important;
        font-weight: 400;
      }
    }
    .custom-active-item {
      color: var(--Dark-Gray-100, #2b3746) !important;
    }
    .ant-menu-item-selected {
      background-color: transparent;
    }

    .ant-menu-item:hover {
      background-color: transparent;
      color: unset !important;
    }
    .ant-menu-item:active {
      background-color: transparent;
    }
    .menu-item:active {
      background-color: transparent;
    }
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: transparent !important;
    }
    .ant-menu-item:not(.ant-menu-item-selected):active {
      background-color: transparent !important;
    }
    .menu-item:hover,
    .menu-item:focus {
      svg #Group path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }

      .badges .ant-badge-count {
        background-color: #4cbbcb !important;
      }
      .title {
        color: var(--Blue-100, #4cbbcb);
        font: 500;
      }
    }
    .custom-active-item {
      svg #Group path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }

      svg path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }

      .badges .ant-badge-count {
        background-color: #4cbbcb !important;
      }
      .title:hover,
      .title:focus {
        font-weight: 500;
        color: var(--Blue-100, #4cbbcb);
      }
    }
    .menu-item-active {
      svg #Group path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }

      .badges .ant-badge-count {
        background-color: #4cbbcb !important;
      }
      .title:hover {
        font-weight: 500;
        color: var(--Blue-100, #4cbbcb);
      }
    }

    .menu-item {
      display: flex;
      justify-content: space-between;
      text-align: start;
      align-self: stretch;
      height: 32px;
      padding-left: 0px !important;
      padding: 0;
      margin: 0;
      svg {
        width: 12px;
        height: 12px;
        flex-shrink: 0;
      }
      .title {
        color: var(--Dark-Gray-100, #2b3746);
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.49px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .badges {
          .ant-badge-count {
            color: var(--White-100, #fff);
            text-align: center;
            font-family: Outfit;
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            background-color: #2b3746;
            right: 2px;
            width: 16px;
            height: 16px;
            border-radius: 5px;
            padding-left: 6px;
          }
        }
      }
    }

    .custom-active-item {
      .title {
        font-weight: 500;
        color: var(--Blue-100, #4cbbcb);
      }
    }
    /* .menu-item:active {
      background-color: transparent !important;
      color: var(--Dark-Gray-100, #2b3746) !important;
    } */
  }
  .app-title {
    color: var(--Dark-Gray-100, #2b3746);

    /* P2 */
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-transform: uppercase;
    width: 100%;
  }

  .sidebar-menu-two {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    width: 208px;
    margin-left: 28px;
    align-self: stretch;
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    border-right: none;
    border-inline-end: none;
    gap: 8px;

    .menu-title {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.49px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
    }

    .menu-item-selected {
      background-color: transparent;
    }
    .menu-item-active {
      background-color: transparent;
    }
    .ant-menu-item-selected {
      background-color: transparent;
    }
    .ant-menu-item:not(.ant-menu-item-selected):hover {
      background-color: transparent !important;
    }
    .menu-item {
      cursor: pointer;
    }
    .menu-item:hover,
    .menu-item:focus {
      /* cursor: context-menu; */
      svg #Group path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }
      svg path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }

      .badges .ant-badge-count {
        background-color: #4cbbcb !important;
      }
      .title {
        color: var(--Blue-100, #4cbbcb);
        font: 500;
      }
    }
    .menu-item-active {
      svg #Group path {
        fill: #4cbbcb !important;
        fill-opacity: unset;
      }

      .badges .ant-badge-count {
        background-color: #4cbbcb !important;
      }
      .title:hover {
        font-weight: 500;
        color: var(--Blue-100, #4cbbcb);
      }
    }
    .menu-item {
      color: var(--Dark-Gray-100, #2b3746);
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.49px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      padding-left: 0px !important;
      padding-right: 0px;
      height: 28px;
      svg {
        width: 12px;
        height: 10.5px;
        flex-shrink: 0;
      }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #2b3746;
        font-family: Outfit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.49px;

        .badges {
          .ant-badge-count {
            color: var(--White-100, #fff);
            text-align: center;
            font-family: Outfit;
            font-size: 9px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            background-color: #2b3746;
            right: 2px;
            width: 16px;
            height: 16px;
            border-radius: 5px;
            padding-left: 6px;
          }
        }
      }
    }
    .menu-title:focus,
    .menu-title:hover {
      .title {
        color: var(--Dark-Gray-100, #2b3746) !important;
        font-weight: 400;
      }
    }
  }
  .logout-div {
    border-bottom: none;
    padding-bottom: 0px !important;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 98px;

    .compnay-watermark {
      color: var(--Gray-20, rgba(85, 99, 115, 0.2));
      text-align: center;
      font-family: Outfit;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.49px;
      margin-bottom: 15px;
    }
  }
`

const SidebarItemsContainer = styled.div`
  height: 100%;
`

const SidebarLogo = styled.div`
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  align-items: flex-start;
  height: 42px;
  border-right: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
  cursor: pointer;
  .startHub-log {
    display: flex;
    position: fixed;
    width: 100%;
    align-items: center;
    padding: 2px 20px;
    gap: 8px;
    width: 272px;
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    height: 42px;
    background-color: white;
    border-right: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    z-index: 2;

    .logo {
      width: 100px;
      height: 26px;
      flex-shrink: 0;
      cursor: pointer;
      flex-shrink: 0;
      object-fit: contain;
    }
  }

  .user-avatar {
    display: flex;
    /* width: 208px; */
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    height: 53px;
    border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));
    .avatar-inner {
      display: flex;
      /* max-width: 208px; */
      width: 100%;
      height: 37px;
      align-items: flex-start;
      gap: 8px;
      flex-shrink: 0;

      .avatar {
        width: 37px;
        height: 37px;
        flex-shrink: 0;
        border-radius: 37px;
      }

      .labels {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* width: 71px; */
        height: 33px;

        .user-name {
          color: var(--Dark-Gray-100, #2b3746);
          font-family: Outfit;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.49px;
          margin: 0;
          padding: 0;
        }
        .user-role {
          color: var(--Gray-70, rgba(85, 99, 115, 0.7));
          font-family: Outfit;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.42px;
        }
      }
    }
  }
`

const SidebarItem = styled.div`
  display: flex;
  width: 272px;
  padding: 11px 0px 16px 0px;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  border-right: 1px solid #e3eced;
  background: #fff;
  box-shadow: 2px 0px 8px 0px rgba(208, 222, 225, 0.1);
  gap: 24px;
  height: calc(100% - 42px);
`

const SearchIcon = styled.div`
  /* width: 208px; */
  height: 56px;
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-bottom: 1px solid var(--Gray-5, rgba(85, 99, 115, 0.05));

  .search-input {
    width: 208px;
    height: 32px;
    display: flex;
    width: 208px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    border: none;
    border-radius: 20px;
    background: var(--Soft-Blue-100, #eff5f6);
    outline: none;
    background-color: #eff5f6;

    svg {
      width: 16px;
      height: 16px;
    }

    input {
      background: transparent;
    }

    input::placeholder {
      color: var(--Gray-60, rgba(85, 99, 115, 0.6));
      font-family: Outfit;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }
  }

  .search-input:hover {
    border: none;
    box-shadow: none;
  }
`

export {
  SideBarContainer,
  SidebarItemsContainer,
  SidebarLogo,
  SidebarItem,
  SearchIcon,
}
